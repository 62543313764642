import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'


const TitleContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
`

const HeroTitle = styled.h1`
  font-weight: 700;
  font-size: 3rem;
  margin: 10px 60px;
  color: #fff;
  text-shadow: 3px 3px 4px rgba(32, 32, 32, 0.6);
`

const HeroSubtitle = styled.h2`
  font-weight: 500;
  font-size: 2rem;
  margin: 10px 35px 28px 35px;
  color: #fff;
  text-shadow: 1px 1px 4px rgba(34, 34, 34, 0.6);
`

class Hero extends React.Component {
  render() {
    const { title, subtitle, className, coverImg } = this.props

    return (
      <BackgroundImage
        Tag="div"
        className={className}
        fluid={coverImg}
        backgroundColor={`#040e18`}
      >
        <TitleContainer>
          <HeroTitle>{title}</HeroTitle>
          <HeroSubtitle>{subtitle}</HeroSubtitle>
        </TitleContainer>
      </BackgroundImage>
    )
  }
}

export default styled(Hero)`
  height: 70vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: table;
  width: 100%;
  overflow: hidden;
 `
